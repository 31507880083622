import { NewOrderBundle } from '@/enums/general'
import { OrderTypes, OrderTypesType } from '@/enums/ota/orders'
import { isMysteryOrder } from '@/utils/ota/orders'

export const isMysteryOrderBundle = (orderBundle: NewOrderBundle) => {
  return !!orderBundle.orders.find((order) => isMysteryOrder(order))
}

export const isOrderBundleOfType = (orderBundle: NewOrderBundle, type: OrderTypesType) =>
  orderBundle.orders.some((order) => order.type === type)

export const isMonthlyOrderBundle = (orderBundle: NewOrderBundle) => isOrderBundleOfType(orderBundle, OrderTypes.membership)

export const hasAtleastOneLoyaltyOrderBundle = (orderBundles: NewOrderBundle[]) =>
  orderBundles.some((orderBundle) => isOrderBundleOfType(orderBundle, OrderTypes.loyalty))
