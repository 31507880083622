import { ChatbotCookiePrefix } from '@/enums/cookies'
import Cookies from 'js-cookie'

export const setCookie = (name: string, value: string, options?: Cookies.CookieAttributes) => {
  Cookies.set(name, value, { ...options, expires: new Date(Date.now() + 604800) })
}

export const getCookie = (name: string) => {
  return Cookies.get(name)
}

export const removeCookie = (name: string, options?: Cookies.CookieAttributes) => {
  Cookies.remove(name, { ...options, domain: getCookieDomain(name) })
}

export const getCookieDomain = (name: string) => {
  const host = window.location.host

  const isChatBotCookie = name.includes(ChatbotCookiePrefix)

  if (host.includes('localhost') || isChatBotCookie) {
    return undefined
  }

  if (host.includes('customerservice')) {
    return host.replace('customerservice', '')
  }

  return host
}
