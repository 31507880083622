import { SenderReceiverTypes } from '@/enums/chat'
import { SupportedLocales } from '@/enums/general'
import { format, isToday, isYesterday, parseISO } from 'date-fns'
import { getDateFNSLocale } from '@/utils/date'
import { ChatIdCookie, OTATokenCookie } from '@/enums/cookies'
import { removeCookie } from '@/utils/cookies'
import { AvailableWorkFlowsType } from '@/enums/workflows/workflows'
import { PageInfo } from '@/core/services/types/getOrderBundles/responseTypes'
import { ExtractOrdersReturnType } from '@/utils/ota/data'
import { isOrderBundleOfType } from '@/utils/ota/orderBundles'
import { OrderTypes } from '@/enums/ota/orders'
import { MessageEntityType } from '@/core/dynamodb'
import { reverseArray } from '@/utils/general'
import { GPTRoles } from '@/enums/gpt'

export const capitalizeFirstLetter = (input: string): string => {
  if (!input || input.length === 0) {
    return input
  }

  return `${input.charAt(0).toUpperCase()}${input.slice(1)}`
}

export const senderReceiverTransformer = (input: string): string => {
  if (input === SenderReceiverTypes.bot) {
    return 'ON THAT ASS customer-service'
  }

  return input
}

type FormatChatISODateProps = {
  dateISO: string
  locale: SupportedLocales
  today: string
  yesterday: string
}

export const formatChatISODate = ({ dateISO, locale, today, yesterday }: FormatChatISODateProps): string => {
  const date = parseISO(dateISO)

  if (isToday(date)) {
    return today
  } else if (isYesterday(date)) {
    return yesterday
  } else {
    return format(date, 'MMMM d, yyyy', { locale: getDateFNSLocale(locale) })
  }
}

export const logOut = () => {
  removeCookie(OTATokenCookie)
  removeCookie(ChatIdCookie)
}

type getShowOrderBundlesProps = {
  workflowName?: AvailableWorkFlowsType
}

const getShowOrderBundles = ({ workflowName }: getShowOrderBundlesProps): boolean => {
  if (
    workflowName === 'paymentPaidButHaventReceivedAnythingLoggedInPickOrder' ||
    workflowName === 'loyaltyOrderStatusLoggedInPickOrder' ||
    workflowName === 'deliveryReceivedWrongProductLoggedIn'
  ) {
    return true
  }

  return false
}

type FilterOrderBundlesProps = {
  workflowName?: AvailableWorkFlowsType
  orderBundles: ExtractOrdersReturnType
}

const filterOrderBundles = ({ workflowName, orderBundles }: FilterOrderBundlesProps) => {
  if (workflowName === 'loyaltyOrderStatusLoggedInPickOrder') {
    return orderBundles.filter((orderBundle) => isOrderBundleOfType(orderBundle, OrderTypes.loyalty))
  }

  return orderBundles
}

type orderBundleHookData =
  | {
      pageInfo: PageInfo
      orderBundles: ExtractOrdersReturnType
    }
  | undefined

type GetOrderBundlesProps = {
  workflowName?: AvailableWorkFlowsType
  orderBundlesData: orderBundleHookData
}

export const getOrderBundles = ({ workflowName, orderBundlesData }: GetOrderBundlesProps): ExtractOrdersReturnType => {
  if (!workflowName || !orderBundlesData) {
    return []
  }

  if (getShowOrderBundles({ workflowName })) {
    return filterOrderBundles({ workflowName, orderBundles: orderBundlesData.orderBundles })
  }

  return []
}

export const formatChatHistory = ({ messages }: { messages: MessageEntityType[] }) => {
  const filteredAndReversedMessages = reverseArray(
    messages.filter(({ category }) => category === 'workflow' || category === 'live-chat')
  )

  const formattedMessages = filteredAndReversedMessages.map((message) => {
    const role = message.sender === 'customer' ? GPTRoles.user : GPTRoles.assistant

    return { role, content: message.message || '' }
  })

  return formattedMessages
}
