import '@/styles/globals.css'
import type { AppProps } from 'next/app'
import { appWithTranslation } from 'next-i18next'
import { Open_Sans } from 'next/font/google'
import Head from 'next/head'
import { ErrorBoundary } from '@/components/ui/ErrorBoundary'

const OpenSans = Open_Sans({
  subsets: ['latin'],
  variable: '--font-os'
})

function App({ Component, pageProps }: AppProps) {
  return (
    <ErrorBoundary>
      <main className={`${OpenSans.variable} font-body h-full`}>
        <Head>
          <link rel="shortcut icon" href="/favicon.ico" />
        </Head>
        <Component {...pageProps} />
      </main>
    </ErrorBoundary>
  )
}

export default appWithTranslation(App)
