import { DeviceImageSources } from '@/core/services/types/getAuthMe/responseTypes'
import { clsx, type ClassValue } from 'clsx'
import { twMerge } from 'tailwind-merge'

export const cn = (...inputs: ClassValue[]) => twMerge(clsx(inputs))

export const isOverflowing = (element: HTMLDivElement | null): boolean => {
  if (!element) {
    return false
  }

  return element.scrollHeight > element.clientHeight
}

type ConvertSrcSetProps = {
  images: DeviceImageSources
}

export const convertSrcSet = ({ images }: ConvertSrcSetProps) => {
  const result = {
    desktop: '',
    mobile: '',
    fallback: ''
  }

  const basePath = process.env.NEXT_PUBLIC_MAIN_WEBSITE_BASEURL!

  if (!images.hasOwnProperty('desktop') && !images.hasOwnProperty('mobile')) {
    return result
  }

  const desktop = images.hasOwnProperty('desktop') ? 'desktop' : 'mobile'
  const mobile = images.hasOwnProperty('mobile') ? 'mobile' : 'desktop'

  return {
    desktop: `${basePath}${images[desktop]['1x']},  ${basePath}${images[desktop]['2x']} 2x`,
    mobile: `${basePath}${images[mobile]['1x']},  ${basePath}${images[mobile]['2x']} 2x`,
    fallback: basePath + images[mobile]['1x']
  }
}
