import { logOut } from '@/utils/chat'
import React, { Component, ErrorInfo, ReactNode } from 'react'
import { withRouter, NextRouter } from 'next/router'
import Button from '@/components/ui/Button'
import * as Sentry from '@sentry/nextjs'
import { clearAllLocalStorageItems } from '@/utils/general'

// Define the props interface
interface ErrorBoundaryProps {
  children: ReactNode
  router: NextRouter // Add router prop
}

// Define the state interface
interface ErrorBoundaryState {
  hasError: boolean
}

class ErrorBoundaryClass extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props)

    // Define a state variable to track whether there is an error or not
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(_: Error): ErrorBoundaryState {
    // Update state so the next render will show the fallback UI
    return { hasError: true }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    // You can use your own error logging service here
    Sentry.captureException({ error, errorInfo })
  }

  render() {
    // Check if the error is thrown
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div className="flex items-center justify-center min-h-full">
          <div className="text-center">
            <div className="pb-4">
              <h2>Oops, there is an error!</h2>
            </div>
            <Button
              type="button"
              onClick={() => {
                logOut()
                
                clearAllLocalStorageItems()
                
                this.props.router.push('/')
                
                setTimeout(() => {
                  this.setState({ hasError: false })
                }, 400)
              }}
            >
              Try again?
            </Button>
          </div>
        </div>
      )
    }

    // Return children components in case of no error
    return this.props.children
  }
}

export const ErrorBoundary = withRouter(ErrorBoundaryClass)
