export const OrderTypes = {
  freeTrial: 0,
  membership: 1,
  intranet: 2,
  promo: 3,
  return: 4,
  loyalty: 6
} as const

export type OrderTypesType = (typeof OrderTypes)[keyof typeof OrderTypes]
