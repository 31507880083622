import { cva, type VariantProps } from 'class-variance-authority'
import { cn } from '@/utils/ui'
import { forwardRef, ReactNode } from 'react'
import LoadingSpinner from '@/components/ui/LoadingSpinner'

const buttonVariants = cva(
  'ota-button relative inline-flex items-center justify-center shadow px-5 py-2 text-sm font-bold', // eslint-disable-line
  {
    variants: {
      variant: {
        secondary: 'bg-secondary-400 text-white rounded border border-secondary-400',
        rounded: 'bg-secondary-400 text-white rounded-[30px] border border-secondary-400',
        'transparent-rounded': 'bg-transparent text-neutral-800 rounded-[30px] border border-neutral-800'
      },
      size: {
        default: 'min-h-[37px]'
      }
    },
    defaultVariants: {
      variant: 'secondary',
      size: 'default'
    }
  }
)

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement>, VariantProps<typeof buttonVariants> {
  loading?: boolean
  icon?: ReactNode
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, children, icon, loading, ...props }, ref) => {
    return (
      <button className={cn(buttonVariants({ variant, size, className }))} ref={ref} {...props}>
        <span>{loading ? <LoadingSpinner className="text-white h-5 w-5" /> : children}</span>
        {!loading && icon}
      </button>
    )
  }
)

Button.displayName = 'Button'

export default Button
